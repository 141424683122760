import create from 'zustand'

export const useSearchParameters = create(set => ({
  checkInDate: null,
  setCheckInDate: (data) => set(state => ({checkInDate: data})),

  checkOutDate: null,
  setCheckOutDate: (data) => set(state => ({checkOutDate: data})),

  totalGuests: 1,
  setTotalGuests: (data) => set(state => ({totalGuests: data})),

  totalRooms: 1,
  setTotalRooms: (data) => set(state => ({totalRooms: data})),
}))